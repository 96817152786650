/* eslint-disable import/prefer-default-export */
import { makeStyles } from '@material-ui/core/styles';
import icon from '../../../images/icon-checked-square.svg';

export const contactFormFiltersStyles = makeStyles((theme) => ({
  wrapper: {
    color: '#1C1C1C',
    padding: '64px 0',
    '@media (max-width: 1024px)': {
      padding: '40px 12px',
    },
  },
  form: {
    marginTop: '64px',
    '@media (max-width: 1024px)': {
      marginTop: '40px',
    },
  },
  textField: {
    marginBottom: 32,
    '@media (max-width: 1024px)': {
      marginBottom: '40px',
    },
  },
  input: {
    marginTop: '8px',
  },
  label: {
    color: '#1C1C1C !important',
    fontWeight: '400 !important',
    fontSize: '14px !important',
    lineHeight: '24px !important',
  },
  showDesktop: {
    display: 'block',
    '@media (max-width: 1024px)': {
      display: 'none',
    },
  },
  showMobile: {
    display: 'none',
    '@media (max-width: 1024px)': {
      display: 'block',
    },
  },
  white: {
    '& input': {
      backgroundColor: '#fff !important',
    },
    '& textarea': {
      backgroundColor: '#fff !important',
    },
  },
  icon: {
    border: '2px solid #101825',
    background: '#fff',
    borderRadius: '2px',
    width: 18,
    height: 18,
  },
  checkedIcon: {
    background: `url(${icon}) center no-repeat`,
    backgroundSize: 'cover',
    border: 'none',
  },
  textArea: {
    margin: '24px 0',
    background: '#F2F2F2',
    border: 'none',
    borderRadius: 0,
    resize: 'none',
    font: `300 14px/24px ${theme.typography.fontFamily}`,
    padding: '12px 41px',
  },
  checkboxList: {
    marginTop: 18,
    padding: '0 8px',
  },
  note: {
    margin: '64px 0',
    '@media (max-width: 1024px)': {
      margin: '40px 0',
    },
  },
  button: {
    color: 'white',
    font: '600 16px/24px normal',
    display: 'flex',
    width: '328px',
    padding: '16px',
    borderRadius: '28px',
    background: '#404042',
    '&:hover': {
      background: '#404042',
    },
  },
}));

export const formSubmittedStyle = makeStyles(() => ({
  container: {
    color: '#101825',
    padding: '88px 0',
    '@media (max-width: 1024px)': {
      padding: '72px 18px',
    },
  },
  titleWrapper: {
    marginBottom: '70px',
    '@media (max-width: 1024px)': {
      marginBottom: '40px',
    },
  },
  title: {
    fontSize: '36px',
    fontFamily: 'Noto Sans JP, Helvetica, sans-serif',
    fontWeight: 900,
    lineHeight: '50px',
    '@media (max-width: 1024px)': {
      fontSize: '24px',
      lineHeight: '34px',
    },
  },
}));

export const salesFormSubmittedStyle = makeStyles(() => ({
  sp: {
    display: 'none',
    '@media (max-width: 1024px)': {
      display: 'block',
    },
  },
}));
