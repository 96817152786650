// @flow
import { Box, Typography, Container } from '@material-ui/core';
import React from 'react';
import { formSubmittedStyle } from './styles';

export default () => {
  const classes = formSubmittedStyle({});

  return (
    <Container maxWidth="md">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        maxWidth="780px"
        m="0 auto"
        className={classes.container}
      >
        <Box textAlign="center" className={classes.titleWrapper}>
          <Typography variant="h2" component="h4" className={classes.title}>
            お申込みありがとうございました。
          </Typography>
        </Box>
        <Typography variant="body1" component="p">
          3営業日以内に担当者よりご連絡させていただきます。
          <br />
          <br />
          サイト内では、導入事例の確認・詳細資料のダウンロードをしていただけます。ぜひご確認の上、お待ちください。
        </Typography>
        {/* <Box mt="70px">
          <Button
            variant="contained"
            color="primary"
            to="/merchant/"
            component={GatsbyLink}
          >
            トップへ戻る
          </Button>
        </Box> */}
      </Box>
    </Container>
  );
};
